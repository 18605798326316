// _responsive-mixins.scss

// Media query mixin for small screens (phones)
@mixin mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  }
  
  // Media query mixin for medium screens (tablets)
  @mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  
  // Media query mixin for large screens (desktops)
  @mixin web {
    @media screen and (min-width: 992px) {
      @content;
    }
  }

  @mixin mob-and-tab {
    @media screen and (max-width: 991px) {
      @content;
    }
  }
  