/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "/src/styles/responsive-mixins.scss";

@font-face {
  font-family: poppins;
  src: url("./assets/fonts/poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: poppins-reg;
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: poppins-semi-bold;
  src: url("./assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: poppins-bold;
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppins-medium;
  src: url("./assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: merriweather;
  src: url("./assets/fonts/merriweather/Merriweather-Regular.ttf");
}
@font-face {
  font-family: merriweather-bold-italic;
  src: url("./assets/fonts/merriweather/Merriweather-BoldItalic.ttf");
}

@font-face {
  font-family: merriweather-bold;
  src: url("./assets/fonts/merriweather/Merriweather-Bold.ttf");
}

html {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: poppins;
}

* {
  scroll-behavior: smooth;
}

.app {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  flex-direction: column;
  flex: 1;
}

.no-padding {
  padding: unset !important;
}

.title {
  color: #241402;
  font-family: poppins-semi-bold;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.form-field {
  margin-top: 10px;

  label {
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .p-inputtext {
    font-family: poppins;
  }

  input::placeholder {
    color: #c1c4c7;
    font-size: 16px;
    font-style: normal;
  }

  .p-dropdown {
    width: 100%;
  }

  .p-calendar {
    width: 100%;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #C1C4C7;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: #fc6805;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem #fff4ed;
    border-color: #fc6805;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #fc6805;
    background: #fff4ed;
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #fff4ed !important;
    border-color: #fc6805 !important;
  }

  .p-inputtext:enabled:hover {
    border-color: #fc6805 !important;
  }

  .error-msg {
    height: 12px;
    color: #fc6805;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  .info-msg {
    color: #868080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .required-field {
    color: #fc6805;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.btn-secondary,
.btn-primary {
  width: 100%;
  display: flex;

  .p-button {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    font-weight: 700;
  }
}

.btn-secondary .p-button {
  background: #4e4d4c;
  border: 1px solid #4e4d4c;
}

.btn-primary .p-button {
  background: #fc6805;
  border: 1px solid#FC6805;
}

.btn-primary .p-button:enabled:hover {
  background: #fc6805;
  color: #ffffff;
  border-color: #fc6805;
}

.btn-secondary .p-button:enabled:hover {
  background: #3f3d3c;
  color: #ffffff;
  border-color: #4e4d4c;
}

.btn-primary .p-button:enabled:active {
  background: #fc6805;
  color: #ffffff;
  border-color: #fc6805;
}

.btn-secondary .p-button:enabled:active {
  background: #4e4d4c;
  color: #ffffff;
  border-color: #4e4d4c;
}

.btn-primary .p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f1d3be, 0 1px 2px 0 black;
}

.btn-secondary .p-button:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b6b2af, 0 1px 2px 0 black;
}

.outlined-btn {
  .btn-primary .p-button {
    background: #ffffff;
    border: 1px solid #FC6805;
    color: #FC6805;
  }

  .btn-primary {
    .p-button {
      width: unset
    }
  }
}

.is-invalid-input {
  border: 1px solid #fc6805 !important;
}

.resend-btn {
  color: #fc6805;
  font-family: poppins-reg;
  text-align: center;
  font-size: 16px;
  font-family: poppins-reg;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.grecaptcha-badge {
  z-index: 1;
}

.radio-btn .p-radiobutton-box {
  width: 20px;
  height: 20px;
  border: 2px solid orange !important;
  background-color: white !important;
  transition: border-color 0.3s ease;
  outline: none !important;
  /* Remove default outline */
  box-shadow: none !important;
  /* Remove any default box shadow */
}

.radio-btn .p-radiobutton-box .p-radiobutton-icon {
  background-color: orange !important;
  border: none !important;
}

.radio-btn .p-radiobutton-box:focus,
.radio-btn .p-radiobutton-box:hover {
  border-color: orange !important;
  box-shadow: none !important;
  outline: none !important;
  /* Remove focus outline */
}

.upload {
  .p-button {
    background: #fc6805 !important;
    border: 1px solid #fc6805 !important;
    border-radius: 4px !important;
  }

  .p-fileupload-row>div {
    width: 100% !important;
  }

  .p-fileupload-row>div>img {
    width: 1000px !important;
  }
}

.p-fileupload .p-fileupload-buttonbar {
  border: none !important;
}

//status-chip styles
.status {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.success {
  background-color: #e4f4d6;
  color: #4ec107;
}

.failure {
  background-color: #ffe9da;
  color: #e90a0a;
}

.pending {
  background-color: #e6ebfa;
  color: #186ded;
}

.p-dialog {
  max-height: 99%;
  border-radius: 30px;
}

// .p-dialog .p-dialog-header {
//   background: #ffffff00 !important;
// }

.registration-modal {
  border-radius: 30px !important;
  max-height: 100% !important;
  width: 90% !important;

  @include mobile {
    width: 100% !important;
    height: 100% !important;
    border-radius: unset !important;
  }


  max-height: 99%;
  border-radius: 30px;

  .p-dialog-header {
    background: #ffffff00 !important;
  }

  .p-dialog-content {
    background: #F8E9DC !important;
    padding: unset !important;

    @include mobile {
      padding: unset !important;
    }
  }

  .p-dialog-header {
    position: fixed;
    right: 0;
    border-radius: 30px !important;

    @include mobile {
      padding: 14px !important;
    }
  }

  .p-dialog-content:last-of-type {
    border-radius: 30px !important;

    @include mobile {
      border-radius: 11.5px !important;
    }
  }

  .p-dialog-header .p-dialog-header-icon:enabled:hover {
    border-color: unset !important;
    background: unset !important;
  }

  .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: unset !important;
  }
}

.delete-modal{
  .p-dialog-content:last-of-type {
    border-radius: 6px !important;
  }
}

.success-modal {
  // border-radius: 30px !important;
  max-height: 100% !important;
  width: 52% !important;

  .p-dialog-content {
    background: #FFF !important;
    padding: unset !important;
  }

  .p-dialog-content:last-of-type {
    border-radius: 6px !important;
  }

  @include mobile {
    width: 90% !important;
    border-radius: unset !important;
  }
  @include tablet {
    width: 70% !important;
    border-radius: unset !important;
  }
}

.p-datatable .p-datatable-thead>tr>th {
  background: #fdf6e6 !important;
  border: none !important;
  padding: 8px !important;
  color: #83817b !important;
  font-family: poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  // opacity: 0.6 !important;
}

.p-datatable .p-datatable-tbody>tr {
  background: #fffbf5 !important;
  color: #333 !important;
  font-family: poppins-reg !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: #fef9ed !important;
  color: #333 !important;
  font-family: poppins-reg !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;

}

.p-datatable .p-datatable-tbody>tr>td {
  border: unset !important;
  font-size: 16px !important;
}

.p-datatable-scrollable-table>.p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pending-status {
  .p-chip {
    background: #e6ebfa;
    color: #186ded;
    border-radius: 5px;
    padding: 2px 14px;
    line-height: 24px;
    width: 110px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.approved-status {
  .p-chip {
    background: #e4f4d6;
    color: #4ec107;
    border-radius: 5px;
    padding: 2px 14px;
    line-height: 24px;
    width: 110px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.rejected-status {
  .p-chip {
    background: #ffe9da;
    color: #e90a0a;
    border-radius: 5px;
    padding: 2px 14px;
    line-height: 24px;
    width: 110px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.custom-paginator {
  margin-top: 2vh;
  .p-paginator {
    background: #fffbf5;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem;
    height: 2rem;
  }
}
.p-radiobutton-label {
  font-weight: 600;
  font-size: 20px !important;
  color: #333333 !important;
  @include mobile{
    font-size: 18px !important;
  }
}

.file-image-view-modal{
  height: 100% !important;
  width: 90% !important;
  ::ng-deep .p-dialog {
    max-height: 100% !important;
  }
}
